<template>
    <!-- SEASONS-->
    <section class="page-section bg-dark" id="seasons">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase text-white">SEASONS</h2>
                <h3 class="section-subheading text-white">Team 7770 has been competing in the FIRST Robotics Competition
                    since 2019.</h3>
            </div>
            <div>
                <div v-for="season in seasons" v-bind:key="season" class="container col-md-12 bg-white rounded p-2 m-2">
                    <div class="row">
                        <div class="col-2">
                            <p><img :src="season.image" height="125" width="125" /></p>
                            
                        </div>
                        <div class="col-sm">
                        <div class="card bg-light">
                            <div class="card-header">{{ season.year }} - {{ season.name }}</div>
                            <div class="card-body">
                                <h5 class="card-title">Robot: {{ season.robot }}</h5>
                                <h5 class="card-title">Features:</h5>
                                <ul class="card-text">
                                    <li v-for="feature in season.features" v-bind:key="feature">{{ feature }}</li>
                                </ul>
                                <h5 class="card-title">Awards:</h5>
                                <ul>
                                    <li v-for="award in season.awards" v-bind:key="award">{{ award }}</li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <iframe height="225" :src="`https://www.youtube.com/embed/${season.youtube}`" title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>                            
                            <div class="card-header section-heading text-uppercase text-white">
                                <a :href="`https://www.thebluealliance.com/team/7770/${season.year}`" target="_results" class="results-link">Results</a>
                            </div>
                        </div>
                        </div>
                        <div class="col-sm">
                            <img class="img-fluid" :src="season.pic" height="350" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script setup>
import { ref } from 'vue'
const seasons = ref([
    /*{
        year: '',
        name: '',
        youtube: '',
        image: require('@/assets/season/')
    },*/
    {
        year: '2025',
        name: 'REEFSCAPE',
        youtube: 'YWbxcjlY9JY',
        image: require('@/assets/season/reefscape-logo-2025.png'),
        robot: "Chum",
        pic: require('@/assets/season/robot2025.png'),
        features: ["Swerve Drive", "Two coral autonomous", "Elevator", "Tilting Shooter"],
        awards: ["Event Winner: CHS District Pasadena MD", "Creativity Award sponsored by Rockwell Automation", "Alliance 7: CHS District Severn MD"]
    },
    {
        year: '2024',
        name: 'CRESCENDO',
        youtube: '9keeDyFxzY4',
        image: require('@/assets/season/crescendo-logo-2024.png'),
        robot: "Hyena",
        pic: require('@/assets/season/robot2024.jpg'),
        features: ["Swerve Drive", "Two note Autonomous"],
        awards: ["Event Finalist: CHS District Severn MD", "Alliance Captain: CHS District Owings Mills MD"]
    },
    {
        year: '2023',
        name: 'CHARGED UP',
        youtube: '0zpflsYc4PA',
        image: require('@/assets/season/chargedup-200-reverse.png'),
        robot: "Armageddon",
        pic: require('@/assets/season/robot2023.jpg'),
        features: ["Claw", "Turret", "Hybrid Tank Drive", "Two cube Autonomous"],
        awards: ["Alliance Captain: CHS District Alexandria, VA", "Event Finalist: CHS District Timonium MD", "CHS District Championship Qualifier"]
    },
    {
        year: '2022',
        name: 'RAPID REACT',
        youtube: 'LgniEjI9cCM',
        image: require('@/assets/season/2022-frc-game-logo.jpg'),
        robot: "Frogger",
        pic: require('@/assets/season/robot2022-climb2.jpg'),
        features: ["Excellent Climbing", "Great Low Shooter", "Low shot Autonomous"],
        awards: ["Alliance Captain: CHS Event 4 Washington, DC", "CHS District Championship Qualifier"]
    },
    {
        year: '2020',
        name: 'Infinite Recharge',
        youtube: 'gmiYWTmFRVE',
        image: require('@/assets/season/2020-frc-game-logo.jpg'),
        robot: "Yoshi",
        pic: require('@/assets/season/robot2020.jpg'),
        features: ["Powerful Shooter", "Tank Drive"],
        awards: ["CHS District Championship Qualifier"]
    },
    {
        year: '2019',
        name: 'DEEP SPACE',
        youtube: 'Mew6G_og-PI',
        image: require('@/assets/season/2019-frc-game-logo.jpg'),
        robot: "Alex 5",
        pic: require('@/assets/season/robot2019.jpg'),
        features: ["Pneumatic Climbing", "Tank Drive"],
        awards: ["Rookie All Star", "Rookie Inspiration", "CHS District Championship Qualifier"]
    }
])
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.results-link {
    color: #000000;
    font-weight: bold;
    text-decoration: none;
}
</style>