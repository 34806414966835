import { createApp } from 'vue'
import { createPinia } from 'pinia'
import 'bootstrap/dist/css/bootstrap.css'
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
window.bootstrap = bootstrap;
import 'startbootstrap-agency/dist/css/styles.css'
import 'startbootstrap-agency/dist/js/scripts'

import App from './App.vue'

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.mount('#app')

// Firebase Realtime Database
import {initializeApp} from 'firebase/app'
import {getDatabase} from 'firebase/database'

const firebaseConfig = {
  // Your Firebase config values here
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: "infinite-voltage-website.firebaseapp.com",
    databaseURL: "https://infinite-voltage-website-default-rtdb.firebaseio.com",
    projectId: "infinite-voltage-website",
    storageBucket: "infinite-voltage-website.firebasestorage.app",
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: "1:740646161865:web:68d0444ef74986c88d6c18",
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
const firebase = initializeApp(firebaseConfig);
const db = getDatabase(firebase);
app.config.globalProperties.$db = db

import {getAnalytics} from 'firebase/analytics'
getAnalytics(firebase)
